import React, { useEffect, useState } from 'react';
import { Stack, Grid, Card, CardHeader, Box, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import useChart from '../../hooks/charts.hook';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getVideosReportData } from '../../services/reports.service';
// import Channels from '../form-elements/channels.component';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';
import { CONTENT_ALIAS } from '../../config/const.config';

const WatchedSecondsChart = () => {
  const [filters, setFilters] = useState({
    channel: null,
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
  });
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: seriesData.map((i) => i.fill) },
    labels: xLabels,
    xaxis: { type: 'date', title: { text: 'Dates' } },
    yaxis: {
      labels: { formatter: (val) => val.toFixed(0) },
      title: { text: 'Platform wise minutes watched' },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} minutes`;
          }
          return y;
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
        },
      },
    },
  });

  // const handleChannelChange = (v) => {
  //   setFilters({
  //     ...filters,
  //     channel: v,
  //     reloadCounter: filters.reloadCounter + 1,
  //   });
  // };
  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleRefreshData = () => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    const payload = {
      startDate,
      endDate,
      reportType: 'date_wise_per_channel_per_platform_total_seconds_watched',
    };
    if (filters.channel?.id) {
      payload.channels = [filters.channel.id];
    }

    getVideosReportData(payload)
      .then((res) => {
        const chartXLabels = [];
        const chartXLabelIndex = {};
        const chartPlatformLabels = [];
        const chartPlatformLabelIndex = {};
        const chartSeriesData = [];

        // Prepare x label index and series data possible length
        res.data.rows.forEach((v) => {
          if (!chartXLabels.includes(v.watchDate)) {
            chartXLabels.push(v.watchDate);
            chartXLabelIndex[v.watchDate] = chartXLabels.length - 1;
          }
          if (!chartPlatformLabels.includes(v.platform)) {
            chartPlatformLabels.push(v.platform);
            chartPlatformLabelIndex[v.platform] =
              chartPlatformLabels.length - 1;
          }
        });

        // Prepare empty dataset
        chartPlatformLabels.forEach((p) => {
          chartSeriesData.push({
            name: p,
            type: 'line',
            data: new Array(chartXLabels.length).fill(0),
          });
        });

        // Append chart dataset
        res.data.rows.forEach((v) => {
          const { platform, watchDate } = v;
          const platformIndex = chartPlatformLabelIndex[platform];
          const watchDateIndex = chartXLabelIndex[watchDate];
          chartSeriesData[platformIndex].data[watchDateIndex] += v.totalMinutes;
        });

        setSeriesData(chartSeriesData);
        setXLabels(chartXLabels);
      })
      .catch(() => {
        // to do
      });
  }, [filters.reloadCounter]);

  return (
    <Card>
      <CardHeader
        title="Watched Minutes"
        subheader={`Platform wise number of ${CONTENT_ALIAS.VIDEO_SINGULAR} watch minutes`}
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container>
          {/* {!DEFAULT_CHANNEL && (
            <Grid item xs={6}>
              <Channels
                id="dr_channel"
                name="dr_channel"
                label="Select Channel"
                defaultValue={filters.channel}
                multiple={false}
                onChange={handleChannelChange}
                sx={{ width: 300 }}
              />
            </Grid>
          )} */}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="right" gap={2}>
              <Datepicker
                id="dr_start_date"
                name="dr_start_date"
                label="Start Date"
                defaultValue={filters.startDate}
                onChange={handleStartDateChange}
                sx={{ marginRight: 2, width: 200 }}
              />
              <Datepicker
                id="dr_end_date"
                name="dr_end_date"
                label="End Date"
                defaultValue={filters.endDate}
                onChange={handleEndDateChange}
                sx={{ width: 200 }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack sx={{ marginTop: 2 }}>
              <ReactApexChart
                type="line"
                series={seriesData}
                options={chartOptions}
                height={364}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default WatchedSecondsChart;
