import React, { useState, useEffect } from 'react';
import { Card, Stack, Button } from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/watch-sessions.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ViewSession from './view-session.component';
import { getDataList } from '../../services/reports.service';

const ListWatchSessions = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  useEffect(() => {
    let uri = 'watch';
    uri += `?page=${options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }

    getDataList(uri)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 1, py: 2 }}>
        <Button
          color="inherit"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
        >
          Refresh
        </Button>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSession
          title={`Watch Session Details - ${doAction.data.sessionId}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          sessionType="watch"
        />
      )}
    </>
  );
};

export default ListWatchSessions;
