import React, { useEffect, useState } from 'react';
import { Stack, Card, CardHeader, Box, IconButton } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import useChart from '../../hooks/charts.hook';
import { getDate, formatDate } from '../../utils/datetime.util';
import { getVideosReportData } from '../../services/reports.service';
import Datepicker from '../form-elements/datepicker.component';
import Iconify from '../common/iconify.component';

const WatchedSessionsChart = () => {
  const [filters, setFilters] = useState({
    startDate: getDate(-7),
    endDate: getDate(0),
    reloadCounter: 0,
  });
  const [seriesData, setSeriesData] = useState([]);
  const [xLabels, setXLabels] = useState([]);
  const chartOptions = useChart({
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      type: 'date',
      categories: xLabels,
    },
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  });

  const handleStartDateChange = (v) => {
    setFilters({
      ...filters,
      startDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleEndDateChange = (v) => {
    setFilters({
      ...filters,
      endDate: v,
      reloadCounter: filters.reloadCounter + 1,
    });
  };
  const handleRefreshData = () => {
    setFilters({
      ...filters,
      reloadCounter: filters.reloadCounter + 1,
    });
  };

  useEffect(() => {
    const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
    const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');

    const payload = {
      startDate,
      endDate,
      reportType: 'date_wise_total_watch_sessions',
    };

    getVideosReportData(payload)
      .then((res) => {
        const chartXLabels = [];
        const chartXLabelIndex = {};
        const chartPlatformLabels = [];
        const chartPlatformLabelIndex = {};
        const chartSeriesData = [];

        // Prepare x label index and series data possible length
        res.data.rows.forEach((v) => {
          if (!chartXLabels.includes(v.watchDate)) {
            chartXLabels.push(v.watchDate);
            chartXLabelIndex[v.watchDate] = chartXLabels.length - 1;
          }
          if (!chartPlatformLabels.includes(v.platform)) {
            chartPlatformLabels.push(v.platform);
            chartPlatformLabelIndex[v.platform] =
              chartPlatformLabels.length - 1;
          }
        });

        // Prepare empty dataset
        chartPlatformLabels.forEach((p) => {
          chartSeriesData.push({
            name: p,
            data: new Array(chartXLabels.length).fill(0),
          });
        });

        // Append chart dataset
        res.data.rows.forEach((v) => {
          const { platform, watchDate } = v;
          const platformIndex = chartPlatformLabelIndex[platform];
          const watchDateIndex = chartXLabelIndex[watchDate];
          chartSeriesData[platformIndex].data[watchDateIndex] +=
            v.totalSessions;
        });

        setSeriesData(chartSeriesData);
        setXLabels(chartXLabels);
      })
      .catch(() => {
        // to do
      });
  }, [filters.reloadCounter]);

  return (
    <Card>
      <CardHeader
        title="Watched Sessions"
        subheader="Platform wise number of watch sessions"
        action={
          <IconButton title="Refresh" onClick={handleRefreshData}>
            <Iconify icon="ic:twotone-refresh" />
          </IconButton>
        }
      />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Datepicker
            id="dr_start_date"
            name="dr_start_date"
            label="Start Date"
            defaultValue={filters.startDate}
            onChange={handleStartDateChange}
            sx={{ marginRight: 2, width: 200 }}
          />
          To
          <Datepicker
            id="dr_end_date"
            name="dr_end_date"
            label="End Date"
            defaultValue={filters.endDate}
            onChange={handleEndDateChange}
            sx={{ width: 200 }}
          />
        </Stack>

        <Stack sx={{ marginTop: 2 }}>
          <ReactApexChart
            type="bar"
            series={seriesData}
            options={chartOptions}
            height={364}
          />
        </Stack>
      </Box>
    </Card>
  );
};

export default WatchedSessionsChart;
