// Define platforms list
const PLATFORMS_LIST = [
  {
    id: 'ROKU',
    title: 'Roku',
  },
  {
    id: 'APPLETV',
    title: 'Apple TV',
  },
  {
    id: 'FIRETV',
    title: 'Fire TV',
  },
  {
    id: 'ANDROIDTV',
    title: 'Android TV',
  },
  {
    id: 'WEB',
    title: 'Web',
  },
];

export const getPlatformLabel = (val) => {
  let label = '';

  PLATFORMS_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const CHART_COLORS = [
  '#6F1AB1',
  '#4CD0F1',
  '#FC4C02',
  '#9DD67D',
  '#3459E0',
];

export default PLATFORMS_LIST;
