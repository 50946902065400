import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Iconify from '../common/iconify.component';
import ListData from '../table-elements/list-data.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/categories.config';
import {
  deleteCategoryById,
  getDataList,
  toggleStatus,
} from '../../services/content.service';
import {
  ACTIONS,
  CONTENT_ALIAS,
  SHOW_MANAGE_SEQUENCE,
  SUBCONTENT_SEQUENCE,
} from '../../config/const.config';
import AddEditCategories from './add-edit-categories.component';
import ViewCategories from './view-categories.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ImagePreview from './image-preview.component';
import ROUTE_PATH from '../../config/routes.config';
import SearchList from '../table-elements/search-list.component';
import UploadAssets from './upload-assets.component';

const ListCategories = ({
  categoryTypeSlug,
  categoryTypeName,
  categoryTypeId,
}) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const searchCategoriesRef = useRef(null);
  const toggleDataRef = useRef(null);
  const [isConfirmationPromptOpen, setIsConfirmationPromptOpen] =
    useState(false);
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [imagePreview, setImagePreview] = useState({
    show: false,
    imageUrl: '',
    title: '',
  });

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });

    setImagePreview({
      ...imagePreview,
      show: false,
    });
  };

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteCategoryById(doAction.data.id)
      .then(() => {
        handleActionSuccess(
          `${CONTENT_ALIAS.CATEGORY_SINGULAR} deleted successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleImagePreview = (rowData) => {
    setImagePreview({
      ...imagePreview,
      show: true,
      imageUrl: rowData.bannerImageUrl,
      title: rowData.title,
    });
  };

  const dataListAPICall = (newOptions = {}) => {
    let uri = 'categories';
    uri += `?type=${categoryTypeSlug}`;
    uri += `&page=${newOptions?.page || options.page + 1}`;
    uri += `&perPage=${options.rowsPerPage}`;
    if (options.sortBy && options.sortOrder) {
      uri += `&sortBy=${options.sortBy}`;
      uri += `&sortOrder=${options.sortOrder}`;
    }
    if (options.q) {
      uri += `&q=${options.q}`;
    } else if (searchCategoriesRef.current?.value) {
      uri += `&q=${searchCategoriesRef.current.value}`;
    }

    setOptions({ ...options, loading: true, searchValue: '' });

    getDataList(uri)
      .then((res) => {
        let serialRow;
        if (res?.data?.rows) {
          serialRow = res.data.rows;
          serialRow = serialRow.map((tr, count) => ({
            ...tr,
            serial: options.page * options.rowsPerPage + count + 1,
          }));
        }

        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: serialRow || [],
          q: null,
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          q: null,
          error: true,
        });
      });
  };

  const handleToggleStatus = (r, value, updateValueFor) => {
    if (
      !value &&
      (updateValueFor === 'isFeatured' || updateValueFor === 'isPlaylist') &&
      !isConfirmationPromptOpen &&
      r.videosCount > 0
    ) {
      toggleDataRef.current = {
        id: r.id,
        value,
        updateValueFor,
        resetVideos: true,
      };
      setIsConfirmationPromptOpen(true);
      return;
    }
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      id: r?.id || toggleDataRef.current.id,
      status: value ?? toggleDataRef.current.value ? 0 : 1,
      type: 'category',
      updateValueFor: updateValueFor || toggleDataRef.current.updateValueFor,
    };

    if (toggleDataRef.current && toggleDataRef.current?.resetVideos) {
      payload.resetVideos = toggleDataRef.current.resetVideos;
    }

    let successToggleMessage = '';
    if (payload.updateValueFor === 'status') successToggleMessage = '';
    else if (payload.updateValueFor === 'showOnVOD')
      successToggleMessage = 'VOD';
    else if (payload.updateValueFor === 'isFeatured')
      successToggleMessage = 'Is Featured';
    else successToggleMessage = 'Is Playlist';

    toggleStatus(payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${successToggleMessage} Status updated.`,
        });

        if (payload.updateValueFor === 'status' || payload?.resetVideos) {
          handleRefreshData();
        }
        toggleDataRef.current = null;
      })
      .catch((e) => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: e.response?.data?.message,
        });
        toggleDataRef.current = null;
      });
  };

  const handleSearchChange = (e) => {
    setOptions({
      ...options,
      searchValue: e.target.value || '',
    });
  };

  const handleSearchCategories = () => {
    setOptions({
      ...options,
      q: searchCategoriesRef.current.value,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleClearSearch = () => {
    setOptions({
      ...options,
      searchValue: '',
      q: null,
      reloadCounter: options.reloadCounter + 1,
    });
    searchCategoriesRef.current.value = '';
  };

  useEffect(() => {
    const newOptions = options;
    newOptions.page = 0;
    setOptions({
      ...options,
      page: 0,
    });

    if (searchCategoriesRef.current?.value) {
      searchCategoriesRef.current.value = null;
    }
    dataListAPICall(newOptions);
  }, [categoryTypeSlug]);

  useEffect(() => {
    dataListAPICall();
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="ic:round-add" />}
            onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
          >
            Create New
          </Button>
          {SHOW_MANAGE_SEQUENCE && (
            <>
              <NavLink
                to={
                  SUBCONTENT_SEQUENCE
                    ? `${ROUTE_PATH.CONTENT_SEQUENCE}${ROUTE_PATH.CONTENT_SEQUENCE_CATEGORY}`
                    : ROUTE_PATH.CONTENT_SEQUENCE
                }
              >
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Iconify icon="pepicons-pop:down-up" />}
                  style={{ marginLeft: 5 }}
                >
                  Manage sequence except playlist
                </Button>
              </NavLink>
              {/* Sequence change for playlist */}
              <NavLink
                to={
                  SUBCONTENT_SEQUENCE
                    ? `${ROUTE_PATH.CONTENT_SEQUENCE}${ROUTE_PATH.CONTENT_SEQUENCE_PLAYLIST}`
                    : ROUTE_PATH.CONTENT_SEQUENCE
                }
              >
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Iconify icon="pepicons-pop:down-up" />}
                  style={{ marginLeft: 5 }}
                >
                  Manage sequence for playlist
                </Button>
              </NavLink>
            </>
          )}

          <SearchList
            label={CONTENT_ALIAS.CATEGORY_PLURAL}
            handleSearch={handleSearchCategories}
            inputRef={searchCategoriesRef}
            onChange={handleSearchChange}
            options={options}
            clearSearch={handleClearSearch}
          />
        </div>
        <div>
          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          handleImagePreview={handleImagePreview}
          handleToggleStatus={handleToggleStatus}
        />
      </Card>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditCategories
          title={`Add New ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
          dataId={0}
          categoryTypeName={categoryTypeName}
          categoryTypeId={categoryTypeId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditCategories
          title={`Edit ${CONTENT_ALIAS.CATEGORY_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          categoryTypeName={categoryTypeName}
          categoryTypeId={categoryTypeId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewCategories
          title={`${CONTENT_ALIAS.CATEGORY_SINGULAR} Details - ${doAction.data.title}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
      {doAction.action === ACTIONS.UPLOAD_BANNER.value && (
        <UploadAssets
          title={`Upload Banner - ${doAction.data.title}`}
          dataId={doAction.data.id}
          dataTitle={doAction.data.title}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          from="category"
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title={`Delete ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
          message={`Do you want to delete ${doAction.data.title} ${CONTENT_ALIAS.CATEGORY_SINGULAR}? You can not undo this action!`}
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {imagePreview.show && (
        <ImagePreview
          title={`Banner Image - ${imagePreview.title}`}
          onCancel={handleActionCancel}
          imageUrl={imagePreview.imageUrl}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
      {isConfirmationPromptOpen && (
        <ConfirmPopup
          title="Confirm Action"
          message="Are you sure, You want to proceed? It will remove all the videos assign to this category"
          onCancel={() => setIsConfirmationPromptOpen(false)}
          onSuccess={() => {
            handleToggleStatus();
            setIsConfirmationPromptOpen(false);
          }}
          cancelButtonText="No"
          successButtonText="Yes"
        />
      )}
    </>
  );
};

ListCategories.propTypes = {
  categoryTypeSlug: PropTypes.string.isRequired,
  categoryTypeName: PropTypes.string.isRequired,
  categoryTypeId: PropTypes.number.isRequired,
};

export default ListCategories;
