import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'thumbnailUrl',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'thumbnailUrl',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '18%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '8%',
    align: 'right',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'preview',
    label: '',
    width: '2%',
    align: 'center',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    width: '15%',
    align: 'center',
    dataKey: 'cdnStatus',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'categoryName',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR}`,
    width: '15%',
    align: 'center',
    dataKey: 'categoryName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdBy',
    label: 'Created By',
    width: '12%',
    align: 'center',
    dataKey: 'createdBy',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    width: '15%',
    align: 'right',
    dataKey: 'modifiedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_ASSETS.value,
    label: ACTIONS.UPLOAD_ASSETS.label,
  },
  {
    action: ACTIONS.ASSIGN_CATEGORIES.value,
    label: ACTIONS.ASSIGN_CATEGORIES.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_VIDEOS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'longDescription',
    label: 'Long Description',
    dataKey: 'longDescription',
    type: CELL_DATA_TYPES.HTML,
  },
  {
    id: 'featuring',
    label: 'Featuring',
    dataKey: 'featuring',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tags',
    label: 'Tags',
    dataKey: 'tags',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'categoryName',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR}`,
    dataKey: 'categoryName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoUrl',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} URL`,
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'thumbnailUrl',
    label: 'Thumbnail URL',
    dataKey: 'thumbnailUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'heroImageUrl',
    label: 'Hero Image URL',
    dataKey: 'heroImageUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdBy',
    label: 'Created By',
    dataKey: 'createdBy',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified Date',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};
