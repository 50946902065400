import React, { useState, useEffect, useRef } from 'react';
import { Button, Stack, Grid } from '@mui/material';
import { LISTING_COLUMNS } from '../../config/module-configs/realtime-live-users.config';
import Iconify from '../common/iconify.component';
import { getRealtimeDashboard } from '../../services/reports.service';
import LiveCountWidget from './live-count-widget.component';

const ListRealtimeLiveUsers = () => {
  const columns = LISTING_COLUMNS;

  const [options, setOptions] = useState({
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    types: [],
    error: false,
    loading: true,
  });
  const [autoLoadCounter, setAutoLoadCounter] = useState(1);
  const refreshInterval = useRef(null);

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleAutoRefreshData = () => {
    setAutoLoadCounter(autoLoadCounter + 1);
  };

  const clearAutoRefreshInterval = () => {
    clearInterval(refreshInterval.current);
  };
  const setAutoRefreshInterval = () => {
    clearAutoRefreshInterval();
    refreshInterval.current = setTimeout(() => {
      handleAutoRefreshData();
    }, 5000);
  };

  useEffect(() => {
    const payload = {
      types: [],
    };
    if (options.sortBy && options.sortOrder) {
      payload.sortBy = options.sortBy;
      payload.sortOrder = options.sortOrder;
    }
    getRealtimeDashboard(payload)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          rows: res?.data?.rows || [],
          error: false,
        });

        setAutoRefreshInterval();
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          rows: [],
          error: true,
        });
      });

    return () => {
      clearAutoRefreshInterval();
    };
  }, [options.reloadCounter, autoLoadCounter]);

  // Check if no live users and set empty rows with default values of 0
  const rowsToShow =
    options.rows.length > 0
      ? options.rows
      : [{ title: 'Grace Awakening Network' }];
  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 1, py: 2 }}>
        <Button
          color="inherit"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
        >
          Refresh
        </Button>
      </Stack>

      {rowsToShow.map(
        // eslint-disable-next-line no-unused-vars
        (r, index) =>
          r.title === 'Grace Awakening Network' && (
            <Grid container spacing={3} mb={3} key={`${index}-${r.title}`}>
              {columns.map((c) =>
                c.label !== 'Total' ? (
                  <Grid item xs={12} sm={3} md={3} key={c.label}>
                    <LiveCountWidget
                      title={c.label}
                      total={r[c.dataKey] || 0}
                      icon={c.icon}
                      color={c.color}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12} key={c.label}>
                    <LiveCountWidget
                      title="Total"
                      total={r[c.dataKey] || 0}
                      color="secondary"
                      hideLogo
                      sx={{ height: 150 }}
                    />
                  </Grid>
                )
              )}
            </Grid>
          )
      )}
    </>
  );
};

export default ListRealtimeLiveUsers;
